<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col
      v-if="!isLoading"
      cols="12"
    >
      <app-card-code
        :title="`${$t('menu.configuration')} / API / ${apikey.api_name}`"
      >
        <v-card-text>
          <APIProveedors
            :api="apikey"
            :proveedores="proveedoresList"
            @getAPI="getAPI"
          />
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'

// eslint-disable-next-line import/no-unresolved
import APIProveedors from './utils/APIProveedors.vue'

export default {
  components: {
    AppCardCode,
    APIProveedors,
  },
  data() {
    return {
      url_doc: process.env.VUE_APP_DOC_URL,
      isLoading: true,
      apikey: {
        api_name: '',
        proveedores: [],
      },
      proveedoresList: [],
    }
  },
  created() {
    this.getAPI()
  },
  methods: {
    getAPI() {
      this.isLoading = true
      this.axios
        .post('api_extern/get-data',
          { id: sessionStorage.getItem('api-id') }, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
        .then(res => {
          if (res.data.success) {
            this.apikey = res.data.data.data
          }
        })
        .finally(() => {
          this.getProveedores()
        })
    },
    getProveedores() {
      this.axios
        .post('proveedors/byproduct', { slug: 'hotels' }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedoresList = res.data.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
